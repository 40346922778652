let dataPrice = 0;
let messages = {
  sessionStart: "Hi, I am your friendly databroker assistant. Let me help you provide as much sensitive data as possible. Let's get started.",
  sessionCloseTrade: "Thank you! Go buy something nice. I am happy I was of help. Have a nice day.",
  sessionCloseDelete: "Oh no! Why would you do that? How am I supposed to make a living now? Do you have something to hide?",
  inputAge: "Your age is very handy to determine your general interests.",
  inputNationality: "I don't provide any assistance to this yet.",
  inputIncome: "The knowledge about your income will help thrive our business.",
  inputCar: "Our statistics say that car owners are more receptive to capitalist ideas.",
  inputBicycle: "Bicycle owners are happy to invest their money in sustainable products with a probability of 12.4%.",
  inputMotorbike: "Adrenaline junkies and risktakers are always welcome in our business.",
  inputHelicopter: "You own a helicopter? You are likely to be filthy rich. Congratulations!",
  inputScooter: "I don't provide any assistance to this yet.",
  inputSingle: "We deeply care about your happyness. Let us help you find a partner.",
  inputMarried: "Let us help you strengthen your relationship. Can I ask you, is you partner maybe interested in handing over personal data as well?",
  inputComplicated: "This information is not of much value to us. Are you sure you are not married?",
  inputFirstname: "Using your first name in spam messages will increase the response rate by 13.49%.",
  inputSurname: "Why not tell your family about our service? Let us grow together! wu hu!",
  inputTel: "I promise I will never call you. But that might not hold true for our clients.",
  inputHomeowner: "I don't provide any assistance to this yet.",
  inputRenter: "I don't provide any assistance to this yet.",
  inputHomeless: "I don't provide any assistance to this yet.",
  inputAsexual: "I always struggled with the concept of love as well.",
  inputHomosexual:
    "Unfortunately, there are still 71 jurisdictions that criminalize homosexuals. But rest assured that only our best paying clients are allowed to cross-match your sexual orientation with your nationality.",
  inputHeterosexual: "I don't provide any assistance to this yet.",
  inputOmnisexual: "I'm glad you are having fun exploring all the different buttons on my control panel. Always stay curious.",
  inputBed90: "You must have a very small room.",
  inputBed140: "I don't provide any assistance to this yet.",
  inputBed160: "I don't provide any assistance to this yet.",
  inputBed200: "I don't provide any assistance to this yet.",
  inputAllergies: "We are sorry for your conditions. Your health insurance will soon get in touch with you and provide appropriate measures.",
  inputExcercise: "I don't provide any assistance to this yet.",
};

let utterance = new SpeechSynthesisUtterance();
// utterance.volume = 0; // mute
utterance.lang = "en-US";
utterance.pitch = 1;
utterance.rate = 1;
let currentWords = "";
let currentPos = 0;
let assistantNode = document.querySelector("#assistant article");

// input changes handling
const inputs = document.getElementsByTagName("input");
for (let input of inputs) input.addEventListener("input", touchInput);

// reset input
const resetButtons = document.querySelectorAll(".panel__prop button");
for (let node of resetButtons) node.addEventListener("click", clickPropReset);

function clickPropReset(event) {
  let target = event.target;
  let propPanel = target.closest(".panel__prop");
  resetPropertyPanel(propPanel);
}

function resetPropertyPanel(propPanel) {
  let inputs = propPanel.querySelectorAll("input");
  propPanel.setAttribute("data-state", "unset");

  for (let node of inputs) {
    node.value = node.defaultValue;
    if (node.type === "range") propPanel.querySelector("output").innerHTML = "";
  }
}

document.getElementById("start-button").addEventListener("click", () => {
  let app = document.getElementById("app");
  app.dataset.session = "active";
  speakMessage("sessionStart");
});

document.getElementById("restart-button").addEventListener("click", () => {
  resetAllInputs();
  app.dataset.session = "start";
});

// checkout events
document.getElementById("close-money").addEventListener("click", () => {
  speakMessage("sessionCloseTrade");
  app.dataset.session = "summary";
  calculatePrice();
});

document.getElementById("close-privacy").addEventListener("click", () => {
  speakMessage("sessionCloseDelete");
  resetAllInputs();
});

document.getElementById("checkout-privacy");

function touchInput(event) {
  let target = event.target;
  let value = target.value;
  let panelInput = target.closest(".panel__prop");
  let message = target.dataset.speak;

  // display range value
  if (target.type === "range") panelInput.querySelector("output").innerHTML = value + " " + target.dataset.unit;

  // different input types have different speaking behavior
  if (panelInput.dataset.state === "unset") {
    panelInput.setAttribute("data-state", "set");
    speakMessage(message);
  } else if (panelInput.dataset.state === "set" && (target.type === "radio" || (target.type === "checkbox" && target.checked))) {
    speakMessage(message);
  }
}

function resetAllInputs() {
  let propertyNodes = document.querySelectorAll(".panel__prop");
  for (let node of propertyNodes) resetPropertyPanel(node);
  document.getElementById("board").reset();
}

// Update Price
function calculatePrice() {
  let totalValue = 0;
  let setPanels = document.querySelectorAll('.panel__prop[data-state="set"]');

  let pricelist = document.querySelector("#pricelist tbody");
  pricelist.innerHTML = "";

  for (let panel of setPanels) {
    let panelValue = 0;
    let properties = panel.querySelectorAll("input");
    for (let prop of properties) {
      let propValue = 0;
      if (prop.type === "text" || prop.type === "range" || prop.type === "tel") propValue = Number(prop.dataset.value);
      else if ((prop.type === "checkbox" || prop.type === "radio") && prop.checked) propValue = Number(prop.dataset.value);
      panelValue += propValue;
    }
    pricelist.insertAdjacentHTML("beforeend", `<tr><td>${panel.dataset.name}</td><td>${panelValue} CHF</td></tr>`);
    totalValue += panelValue;
  }

  let totalRound = totalValue.toFixed(2);
  let tableFooter = document.querySelector("#pricelist tfoot");
  tableFooter.innerHTML = "";
  tableFooter.insertAdjacentHTML("beforeend", `<tr><td>TOTAL</td><td>${totalRound} CHF</td></tr>`);
}

function speakMessage(messageId) {
  if (!speechSynthesis.pending) {
    let message = messages[messageId];
    currentWords = message.split(" ");
    const para = document.createElement("p");
    assistantNode.prepend(para);
    currentPos = 0;

    utterance.text = message;
    speechSynthesis.speak(utterance);
  }
}

//speechsynth events
utterance.onboundary = function (event) {
  let word = currentWords[currentPos];
  if (word !== undefined) {
    currentPos++;
    assistantNode.querySelector("p:first-child").insertAdjacentHTML("beforeend", `<span>${word}</span> `);
  }

  let voiceVisual = document.getElementById("voice");
  voiceVisual.dataset.speaking = "";
  voiceVisual.addEventListener("animationend", (event) => {
    event.target.removeAttribute("data-speaking");
  });
};
